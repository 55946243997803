.about-container {
    height: 70vh;
    background: linear-gradient(
        to right,
        rgb(237, 218, 248) 0%,
        rgb(252, 191, 218) 50%,
        hsla(211, 58%, 79%, 1) 100%
    );
    display: flex;
    align-self: center;
}

.about-card {
    margin: auto;
    width: 80vw;
    height: 65vh;
    background-image: url("https://cdn.wallpapersafari.com/71/8/Vy3ZxE.jpg");
    background-repeat: no-repeat;
    border-radius: 50px;
    border: 3px plum double;
    background-size: cover;
    background-position-y: 7%;
}

.title {
    font-family: "BrokenConsoleReg";
    letter-spacing: 0.4em;
    color: lightcoral;    
    font-size: 1.3em;
    margin-top: 1em;
}

#avatar {
    object-fit: contain;
    width: 40px;
    height: 40px;
    display: inline;
    position: sticky;
    transform: scale(1.4, 1.4);
}

.about-left {
    position: relative;
    width: 20vw;
    height: 45vh;
    display: block;
    justify-content: center;
    margin-left: 5%;
}

.about-profile-photo {
    margin-left: 15%;
    margin-top: 6vh;
    position: relative;
    float: left;
}

#profile-border {
    width: 25vh;
    height: 25vh;
    margin-left: 8%;
    margin-top: -14%;
    position: absolute;
    z-index: 1;
}

#profile-pic {
    border-radius: 100px;
    width: 18vh;
    height: 18vh;
    margin-left: 27%;
    position: relative;
    margin-top: 4%;
}

.about-body {
    display: grid;
    grid-template-columns: auto auto;
}

.about-left-body {
    position: relative;
    top: 3em;
    left: clamp(20%,22%,24%);
    clear: both;
    width: 220px;
}

.about-left-body > h3 {
    margin-left: 7%;
    border-radius: 15px;
    color: rgb(170,157,232);
    background-color: rgba(241,214,255,0.7);
    border: 3px #dc9db0 double;
}

#sakura-bot {
    height: 2vy;
    width: 2vw;
    margin-left: 88%;
    top: 34px;
    transform: rotate(180deg);
    position: absolute;
}

#sakura-top {
    height: 2vy;
    width: 2vw;
    margin-left: 5%;
    top: 0;
    position: absolute;
}

.about-left-socials {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1em;
}

#github:hover, #linkedin:hover{
    transform: scale(1.1);
    transition: 0.4s ease-in-out;
    border: 1px silver outset;
}

.about-left-socials h6 {
    color: grey;
    font-size: 1em;
}

.about-right {
    font-family: "dogica";
    word-spacing: 0.2rem;
    letter-spacing: 0.2rem;
    display: grid;
    height: 51vh;
    width: 57vw;
    background-color: rgba(250, 235, 215, 0.308);
    border: 3px rgba(241, 232, 155, 0.877) solid;
    border-radius: 25px;
    padding: 1% 2%;
    margin-right: 5%;
    text-align: left;
    overflow-y: auto;
}

.about-right h1 {
    color: rgb(170,157,232);
    font-size: 25px;
}

.about-right h5 {
    color: gray;
    letter-spacing: 0;
}

.about-right span, a {
    color: rgb(214, 76, 248);
}

@media screen and (max-width:480px) {
    
    .about-card {
        height: auto;
    }

    .about-title {
        font-size: 0.8em;
    }

    #avatar {
        object-fit: contain;
        transform: scale(1, 1);
        margin-top: -0.4em;
    }

    .about-left {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .about-profile-photo {
        margin-top: 0;
        margin-right: 30vw;
    }

    .about-left-body {
        left: 0%;
    }

}