.sticky-header {
    align-self: center;
    height: fit-content;
    width: 100vw;
    border: 3px solid rgba(255,255,255,.3);
    border-top: 10px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(252, 215, 242, 0.398);
    display: flex;
    justify-content: space-around;
    /* position: fixed;
    top: 0; */
}

.sticky-header-nav > * {
    font-family: serif, Arial, Helvetica, sans-serif;
    font-size: 125%;
    border-bottom: 3px solid rgba(255,255,255,.3);
    color: #4A4A4A;
    margin-left: 1em;
}