.projects-container {
    background: linear-gradient(
        to right,
        rgb(237, 218, 248) 0%,
        rgb(252, 191, 218) 50%,
        hsla(211, 58%, 79%, 1) 100%
    );
    width: 100vw;
    min-height: 100vh;
    height: max-content;
    position: relative;
}

.projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px,1fr));
    grid-gap: 30px;
    justify-content: center;
    margin: 20px;
    margin-top: 80px;
}

.project-title {
    display: flex;
    justify-content: center;
}

.project-title-banner {
    align-self: center;
    border: 3px solid rgb(181, 176, 176);
    display: inline-block;
    padding: 0em 0.5em;
    background-size: contain;
    color: rgb(113,112,115);
    color: rgb(148, 147, 149);
    background-image: url('./backgroundtitle.jpg');
}

.project-title-banner h1 {
    margin-top: 0.2em;
}

.project-title img {
    height: 6em;
    width: auto;
}

.card > * {
    background-color: #e9e7e7d4;
    border-radius: 20px;
    padding: 20px;
    position: relative;
}


.project-thumbnail {
    width: 455px;
    height: 220px;
    object-fit: cover;
    border-radius: 20px;
    margin: 0 auto;
}

.project-thumbnail-container {
    background-image: url("https://as1.ftcdn.net/v2/jpg/03/44/57/58/1000_F_344575885_2EGHG68pEUAuXXstVEjLn91y9VN6QFaI.jpg");
    padding: 5px;
    border-radius: 20px;
}

.project-body {
    margin-top: 20px;
    border-radius: 20px;
    border: 3px solid rgba(22, 182, 212, 0.3);
    background-image: url("./vaporwavebg.jpg");
    opacity: 0.5;
    color: black;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.tags h3 {
    margin-left: 1em;
    background-color: rgba(128, 128, 128, 0.607);
    padding: 0 0.3em;
    font-size: small;
    border-radius: 5px;
}

.project-desc, .project-purpose {
    display: flex;
    text-align: left;
    margin: 0.25em 1em;
}

.project-demo {
    position: absolute;
    bottom: 0px;
    right: 10px;
}

.project-demo a:hover {
    color: black;
}

#graphic-design {
    width: 15em;
    position: absolute;
    right: 2em;
    top: 5em;
}