.home-container {
	height: 100vh;
	scroll-snap-type: y mandatory;
	overflow-y: scroll;
}

.home-container > * {
	scroll-snap-align: start;
	overflow-y: scroll;
}

.projects-page-container {
	scroll-snap-align: none;
}