@import url("https://cdn.jsdelivr.net/npm/hack-font@3/build/web/hack.css");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
}

:root {
  --color-bg: #f8bbd0;
  --color-bg-variant: #c5cae9;
  --color-primary: #f9e791;
  --color-primary-variant: white;
  --color-hover: #FEF5C8;
  --color-light: #FAF7FA;
  --color-link: rgb(214, 76, 248);

  --transition: all 400ms ease;

  --container-width-lg: 70%;
  --container-width-md: 85%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Hack, monospace;
  background-color: var(--color-bg) ;
  color: var(--color-primary-variant);
  line-height: 1.7;
}

::-webkit-scrollbar {
  display: none;
}

/* .container {
  margin: 0 auto;
  width: var(--container-width-lg);
} */

section {
  margin-top: 8rm;
}

a {
  color: var(--color-link);
  transition: var(--transition);
}

a:hover {
  color: var(--color-hover);
}

.btn {
  color: var(--color-primary);
  display: inline-block;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rm;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background:var(--color-primary-variant) ;
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

@font-face {
  font-family: "BrokenConsoleReg" ;
  src: local(BrokenConsole),
  url("./utils/Broken Console Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "BrokenConsoleBold" ;
  src: local(BrokenConsole),
  url("./utils/Broken Console Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Fipps" ;
  src: local(BrokenConsole),
  url("./utils/Fipps-Regular.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "dogica" ;
  src: local(BrokenConsole),
  url("./utils/dogica.ttf") format("truetype");
  font-weight: normal;
}