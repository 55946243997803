.about {
    min-height: 100vh;
    height: 100%;
}

.blog-container {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.blogs {
    display: flex;
    flex-direction: column;
}

.blog-card {
    height: 20vh;
    background-color: #e9e7e7d4;
    display: flex;
    justify-content: start;
    margin: 1em;
}

.blog-card-thumbnail {
    height: 18vh;
    width: 15vw;
    object-fit: cover;
    display: flex;
    align-self: center;
    padding: 1em;
}

.blog-card .tags h3:first-child {
    margin-left: 0em;
}

.blog-card-body {
    width: 80vw;
    text-align: left;
    margin-left: 2vw;
}

.blog-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog-card-content {
}

.blog-card-slug {
}

.blog-card-desc {
}