.header {
    background: url('./headerbg.gif');
    background-size: 100% 110%;
    background-position: center;
    background-repeat: no-repeat;
    height: 30vh;
    width: 100vw;
    display: flex;
    justify-content: center;
}

.header-box {
    align-self: center;
    height: 80%;
    width: 80%;
    box-shadow: 0 0.3px 0.7px rgba(0, 0, 0, 0.126),
    0 0.9px 1.7px rgba(0, 0, 0, 0.179), 0 1.8px 3.5px rgba(0, 0, 0, 0.224),
    0 3.7px 7.3px rgba(0, 0, 0, 0.277), 0 10px 20px rgba(0, 0, 0, 0.4);
    transition: 0.5s ease;
    border: 3px solid rgba(255,255,255,.5);
    border-top: 10px solid rgba(255,255,255,.5);
}

.header-nav {
    font-family: serif, Arial, Helvetica, sans-serif;
    font-size: 125%;
    background-color: rgba(252, 215, 242, .5);
    border-bottom: 3px solid rgba(255,255,255,.5);
    justify-content: left;
    display: flex;
}

.header-nav a {
    color: #4A4A4A;
    margin-left: 1em;
}

.header-content {
    backdrop-filter: blur(20px);
    height: 82%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.font-face-bcr {
    font-family: "BrokenConsoleReg";
    color: white;
    font-size:1.5rem;
    letter-spacing: 0.2em;
    font-weight: bold;
}

.font-face-bcrblink {
    font-family: "BrokenConsoleReg";
    color: white;
    font-size: 15pt;
    letter-spacing: 0.2em;
    animation: blinker 1s linear infinite;
    font-weight: bold;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media screen and (max-width: 480px) {
    .header-content > * {
        font-size: 1em;
    }
}